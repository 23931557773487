<template>
    <div class="add_form" :class="{isStocks:isStartOrStop}">
        <!-- 高抛低吸机器人 -->
        <div class="form buy_sell" v-if="robotType=='buy_sell'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.higherthan')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit red block">{{$t('robot.sellout')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.below')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit green block">{{$t('robot.purchase')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[2]" @input.native="inputHandler2($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.per')}}</div>
                <div class="text wrap">
                    <div>
                        <el-input :disabled="paramArr[4]==1||paramArr[7]==1" v-model="paramArr[3]"
                            @input.native="inputHandler($event,3)" :placeholder="$t('common.value')" class="inp" />
                        <span class="unit">{{yenUnit1_Up}}</span>
                    </div>
                    <el-checkbox v-if="!isStartOrStop" true-label="1" false-label="0" v-model="paramArr[4]">
                        {{$t('robot.opponents')}}
                    </el-checkbox>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maximum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('robot.maximumPH1')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.minimum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[6]" @input.native="inputHandler($event,6)"
                        :placeholder="$t('robot.minimumPH1')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>

            <template v-if="!isStartOrStop">
                <div class="item">
                    <div class="title">{{$t('robot.working')}}</div>
                    <div class="text">
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[7]">{{$t('robot.chuck')}}
                        </el-checkbox>
                    </div>
                </div>
                <div class="item">
                    <div class="title">{{$t('robot.limitOrder')}}</div>
                    <div class="text">
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[8]"></el-checkbox>
                    </div>
                </div>
                <div class="item">
                    <div class="title">{{$t('robot.tradeSelfOrder')}}</div>
                    <div class="text">
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[9]"></el-checkbox>
                    </div>
                </div>
            </template>

            <div class="checkBox" v-else>
                <div class="checkboxItem">
                    <span class="label">{{$t('robot.opponents')}}</span>
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[4]"></el-checkbox>
                </div>
                <div class="checkboxItem">
                    <span class="label">{{$t('robot.working')}}</span>
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[7]">
                        <span class="tipLabel">{{$t('robot.chuck')}}</span>
                    </el-checkbox>
                </div>
                <div class="checkboxItem">
                    <span class="label">{{$t('robot.limitOrder')}}</span>
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[8]"></el-checkbox>
                </div>
                <div class="checkboxItem">
                    <span class="label">{{$t('robot.tradeSelfOrder')}}</span>
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[9]"></el-checkbox>
                </div>
            </div>
        </div>
        <!-- 分批卖出机器人 -->
        <div class="form iceberg" v-else-if="robotType=='iceberg'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.floorPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval2')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[1]" @input.native="inputHandler2($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityPerTime')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.frequency')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 跨所对冲机器人-搬砖 -->
        <div class="form banzhuan" v-else-if="robotType=='banzhuan'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.targetExchange')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly
                        class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetPair')}}</div>
                <div class="doubletext">
                    <el-input v-model="targetPair1" type="text" :placeholder="$t('common.currency')" class="inp" />
                    <i class="split"></i>
                    <el-input v-model="targetPair2" type="text" :placeholder="$t('common.currency')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.currentCharge')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>

                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetCharge')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 马丁策略量化机器人 下架 -->
        <div class="form wangge" v-else-if="robotType=='wangge'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.openingAmount')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.repTimes')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler2($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.ci')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interferenceRange')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.stopBackRatio')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.repDifferences')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addBackRatio')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item" v-if="!isStartOrStop">
                <div class="title">{{$t('robot.firstDouble')}}</div>
                <div class="text">
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[6]"></el-checkbox>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addMultiple')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[7]" @input.native="inputHandler($event,7)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.times')}}</span>
                </div>
            </div>

            <div class="checkBox" v-if="isStartOrStop">
                <div class="checkboxItem">
                    <span class="label">{{$t('robot.firstDouble')}}</span>
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[6]"></el-checkbox>
                </div>
            </div>
        </div>
        <!-- 网格策略量化机器人 下架 -->
        <div class="form grid" v-else-if="robotType=='grid'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.firstAmount')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.grids')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler2($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.stopRatio')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addInterval')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addIntervalIncrease')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.times')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.addAmountIncrease')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.times')}}</span>
                </div>
            </div>
        </div>

        <!-- 成交量对敲机器人 -->
        <div class="form shua" v-else-if="robotType=='shua'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.counterQuantity')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.perMinute')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler2($event,2)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">{{$t('robot.ci')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.numTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <temlate v-if="!isStartOrStop">
                <div class="item">
                    <div class="title">{{$t('robot.clearOrder')}}</div>
                    <div class="text">
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[6]"></el-checkbox>
                    </div>
                </div>
                <div class="item">
                    <div class="title">{{$t('robot.advancedProtection')}}</div>
                    <div class="text">
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[7]"></el-checkbox>
                    </div>
                </div>
                <div class="item">
                    <div class="title">{{$t('robot.sellOrderFirst')}}</div>
                    <div class="text">
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[8]"></el-checkbox>
                    </div>
                </div>
            </temlate>
            <template v-else>
                <div class="checkBox">
                    <div class="checkboxItem">
                        <span class="label">{{$t('robot.clearOrder')}}</span>
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[6]"></el-checkbox>
                    </div>
                    <div class="checkboxItem">
                        <span class="label">{{$t('robot.advancedProtection')}}</span>
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[7]"></el-checkbox>
                    </div>
                    <div class="checkboxItem">
                        <span class="label">{{$t('robot.sellOrderFirst')}}</span>
                        <el-checkbox true-label="1" false-label="0" v-model="paramArr[8]"></el-checkbox>
                    </div>
                </div>
            </template>
        </div>
        <!-- 盘口管理机器人 -->
        <div class="form budan_new" v-else-if="robotType=='budan_new'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.orderPerNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellPerNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('robot.perDifferencePH1')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maintenance')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.numTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[6]" @input.native="inputHandler2($event,6)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 买盘护盘机器人 -->
        <div class="form hupan_buy" v-else-if="robotType=='hupan_buy'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.buyPriceRange')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0,100)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1,100)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.protective')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('robot.protectivePH')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>

                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gear')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('robot.gearPH')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('robot.priceTickPH8')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.numTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('robot.numTickPH8')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 卖盘护盘机器人 -->
        <div class="form hupan_sell" v-else-if="robotType=='hupan_sell'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.sellPriceRange')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0,100)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1,100)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.protective')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('robot.protectivePH')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gear')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('robot.gearPH')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('robot.priceTickPH8')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.numTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('robot.numTickPH8')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 趋势推动机器人 -->
        <div class="form push" v-else-if="robotType=='push'">
            <div class="itemSwitch">
                <div class="switch">
                    <el-radio-group v-model="sub_type_select">
                        <el-radio-button label="1">{{$t('robot.buyBtn')}}</el-radio-button>
                        <el-radio-button label="2">{{$t('robot.sellBtn')}}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>

            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.plan')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit"
                        :class="sub_type_select==1?'green':'red'">{{sub_type_select==1?yenUnit2_Up:yenUnit1_Up}}</span>
                </div>
            </div>

            <!-- <div class="item">
                <div class="title">{{$t('robot.intervalRange')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler2($event,1)" :placeholder="$t('common.minValue')" :min="1" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[2]" @input.native="inputHandler2($event,2)" :placeholder="$t('common.maxValue')" :min="1" class="inp" />
                    <span class="unit">{{$t('robot.random')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perInterval')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)" :placeholder="$t('common.value')" class="inp" />
                </div>
            </div> -->

            <div class="item">
                <div class="title">{{$t('robot.singleBudget')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit"
                        :class="sub_type_select==1?'green':'red'">{{sub_type_select==1?yenUnit2_Up:yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.endTime')}}</div>
                <div class="text">
                    <el-date-picker :editable="false" :clearable="false" class="datetime inp" v-model="paramArr[2]"
                        value-format="timestamp" type="datetime" :placeholder="$t('common.selectdate')"
                        :picker-options="pickerNowAfter" />
                    <!-- <dateTime :paramTime.sync="paramArr[2]"></dateTime> -->
                </div>
            </div>

            <!-- <div class="item">
                <div class="title">{{$t('robot.startPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)" :placeholder="$t('common.value')" class="inp" />
                </div>
            </div> -->
            <div class="item">
                <div class="title">{{$t('robot.targetPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 新：成交放量机器人 -->
        <div class="form hugevol" v-else-if="robotType=='hugevol'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.tradingVolume')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}/{{$t('robot.minute')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.workingHours')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler2($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.minutes')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.splitBudget')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 新：防御机器人 -->
        <div class="form defence" v-else-if="robotType=='defence'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.orderMin')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.kaNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.eatNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maxNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.minNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.numTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[6]" @input.native="inputHandler2($event,6)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>

        <!-- 画线机器人 -->
        <div class="form draw" v-else-if="robotType=='draw'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.upperLimit')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.lowerLimit')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('robot.perDifferencePH1')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.orderPerNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>

                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellPerNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.singleMax')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[6]" @input.native="inputHandler($event,6)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[7]" @input.native="inputHandler2($event,7)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.swiping')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[8]" @input.native="inputHandler($event,8)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.direction')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[9]" :placeholder="$t('common.select')">
                        <el-option :label="$t('robot.normalMode')" value="0">
                        </el-option>
                        <el-option :label="$t('robot.indexFollow')" value="1">
                        </el-option>
                        <el-option :label="$t('robot.customMode')" value="10">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <template v-if="paramArr[9]==10">
                <div class="item">
                    <div class="title">{{$t('robot.sequentialPlan')}}</div>
                    <div class="text">
                        <el-select class="inp" v-model="paramArr[10]" :placeholder="$t('common.select')"
                            @change="getSequenceInfo" :value-key="'id'">
                            <el-option v-for="(sequence,index) in priceSequenceList" :key="index"
                                :label="getSequenceName(sequence)" :value="sequence.id">
                                <span class="systemPlan" v-if="sequence.type == 1">{{ $t('robot.systemPlan') }}</span>
                                <span class="customPlan" v-else>{{ $t('robot.customPlan') }}</span>
                                {{getSequenceName(sequence)}}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 序列点位 -->
                <template v-if="!isStartOrStop">
                    <div class="item alignStart" v-for="(data, index) in timePriceList" :key="index">
                        <div class="title">{{ index==0?$t('robot.sequential'):''}}</div>
                        <div class="rangeBox">
                            <div class="subTitle">
                                <span>{{$t('robot.position')+(index+1)}}</span>
                                <span class="g_btn" @click="timePriceList.length>1?timePriceList.splice(index,1):null">
                                    <i class="iconfont2 icon-shanchu"></i>
                                </span>
                            </div>
                            <div class="timePriceBox">
                                <el-date-picker :editable="false" v-model="data.time" class="inp sequenceTime"
                                    type="datetime" :placeholder="$t('robot.timePoint')" value-format="timestamp"
                                    :clearable="false">
                                </el-date-picker>
                                <!-- <dateTime :paramTime.sync="data.time"></dateTime> -->
                                <el-input v-model="data.price" @input.native="inputHandlerPrice($event,index)"
                                    :placeholder="$t('robot.target')" class="inp" />
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title"></div>
                        <div class="addBtn" @click="timePriceList.push({time:'',price:''})">
                            <i class="iconfont2 icon-jia"></i>
                            {{$t('robot.newPosition')}}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="itemBox" v-for="(data, index) in timePriceList" :key="index">
                        <div class="subTitle">
                            <span>{{$t('robot.position')+(index+1)}}</span>
                            <span class="g_btn" @click="timePriceList.length>1?timePriceList.splice(index,1):null">
                                <i class="iconfont2 icon-shanchu"></i>
                            </span>
                        </div>
                        <div class="timePriceBox">
                            <el-date-picker :editable="false" v-model="data.time" class="inp sequenceTime"
                                type="datetime" :placeholder="$t('robot.timePoint')" value-format="timestamp"
                                :clearable="false">
                            </el-date-picker>
                            <!-- <dateTime :paramTime.sync="data.time"></dateTime> -->
                            <el-input v-model="data.price" @input.native="inputHandlerPrice($event,index)"
                                :placeholder="$t('robot.target')" class="inp" />
                        </div>
                    </div>
                    <div class="itemBox g_btn btn" @click="timePriceList.push({time:'',price:''})">
                        <i class="iconfont2 icon-jia"></i>
                        {{$t('robot.newPosition')}}
                    </div>
                </template>
            </template>
            
            <div class="item">
                <div class="title">{{$t('robot.quantityDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[11]" @input.native="inputHandler($event,11)"
                        :placeholder="$t('robot.quantityDecimal')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 跟盘机器人 -->
        <div class="form real_genpan" v-else-if="robotType=='real_genpan'">
            <div class="itemSwitch">
                <div class="switch">
                    <el-radio-group v-model="sub_type_select">
                        <el-radio-button label="1">{{$t('robot.standard')}}</el-radio-button>
                        <el-radio-button label="2">{{$t('robot.rebalance')}}</el-radio-button>
                    </el-radio-group>
                </div>
            </div>

            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.targetDisk')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly
                        class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.targetPair')}}</div>
                <div class="doubletext">
                    <el-input v-model="targetPair1" type="text" :placeholder="$t('common.currency')" class="inp" />
                    <i class="split"></i>
                    <el-input v-model="targetPair2" type="text" :placeholder="$t('common.currency')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.scaling2')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('robot.perDifferencePH1')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.orderPerNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellPerNum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[6]" @input.native="inputHandler($event,6)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.singleMax')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[7]" @input.native="inputHandler($event,7)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[8]" @input.native="inputHandler2($event,8)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.swiping')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[9]" @input.native="inputHandler($event,9)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[10]" @input.native="inputHandler($event,10)"
                        :placeholder="$t('robot.quantityDecimal')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 高级盘口管理机器人（控盘） -->
        <div class="form kongpan" v-else-if="robotType=='kongpan'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <!-- <div class="item">
                <div class="title">{{yenUnit1_Up+$t('robot.budget')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{yenUnit2_Up+$t('robot.budget')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div> -->
            <div class="item">
                <div class="title">{{$t('robot.benchmarkPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <!-- 小单 -->
            <div class="item">
                <div class="title">{{$t('robot.paySmall')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellSmall')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[6]" @input.native="inputHandler($event,6)"
                        :placeholder="$t('robot.perDifferencePH1')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gears')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[7]" @input.native="inputHandler($event,7)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <!-- 中单 -->
            <div class="item">
                <div class="title">{{$t('robot.payMedium')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[8]" @input.native="inputHandler($event,8)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellMedium')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[9]" @input.native="inputHandler($event,9)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[10]" @input.native="inputHandler($event,10)"
                        :placeholder="$t('robot.perDifferencePH1')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gears')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[11]" @input.native="inputHandler($event,11)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <!-- 大单 -->
            <div class="item">
                <div class="title">{{$t('robot.payLarge')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[12]" @input.native="inputHandler($event,12)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.sellLarge')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[13]" @input.native="inputHandler($event,13)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[14]" @input.native="inputHandler($event,14)"
                        :placeholder="$t('robot.perDifferencePH1')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gears')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[15]" @input.native="inputHandler($event,15)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[16]" @input.native="inputHandler2($event,16)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[17]" @input.native="inputHandler2($event,17)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>

        </div>
        <!-- 主流币跟盘机器人 -->
        <div class="form real_genpan_quick" v-else-if="robotType=='real_genpan_quick'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{yenUnit1_Up+'/'+yenUnit2_Up}} {{$t('robot.targetDisk')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly
                        class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.difference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.perDifference')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('robot.perDifferencePH1')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.buy')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.offers')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.swiping')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[6]" @input.native="inputHandler($event,6)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.numTick')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[7]" @input.native="inputHandler2($event,7)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 新：开盘机器人 -->
        <div class="form open_cex" v-else-if="robotType=='open_cex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.openingTime')}}</div>
                <div class="text">
                    <el-date-picker :editable="false" :clearable="false" class="datetime inp" v-model="paramArr[0]"
                        value-format="timestamp" type="datetime" :placeholder="$t('common.selectdate')"
                        :picker-options="pickerNowAfter" />
                    <!-- <dateTime :paramTime.sync="paramArr[0]"></dateTime> -->
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.openPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.topPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                        <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.downPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.pullOrdersUpAndDown')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>

        <!-- Dex画线机器人 -->
        <div class="form shua_dex" v-else-if="robotType=='shua_dex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.priceRange')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transaction')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.intervalTime')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.operationMode')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[5]" :placeholder="$t('common.select')">
                        <el-option :label="$t('robot.normalMode')" value="0">
                        </el-option>
                        <el-option :label="$t('robot.batchOrderMode')" value="1">
                        </el-option>
                        <el-option :label="$t('robot.unlimitedWalletMode')" value="2">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.direction')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[6]" :placeholder="$t('common.select')">
                        <el-option :label="$t('robot.oscillation')" value="0">
                        </el-option>
                        <el-option :label="$t('robot.rise')" value="1">
                        </el-option>
                        <el-option :label="$t('robot.fall')" value="2">
                        </el-option>
                        <el-option :label="$t('robot.customMode')" value="10">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <template v-if="paramArr[6]==10">
                <div class="item">
                    <div class="title">{{$t('robot.sequentialPlan')}}</div>
                    <div class="text">
                        <el-select class="inp" v-model="paramArr[7]" :placeholder="$t('common.select')"
                            @change="getSequenceInfo" :value-key="'id'">
                            <el-option v-for="(sequence,index) in priceSequenceList" :key="index"
                                :label="getSequenceName(sequence)" :value="sequence.id">
                                <span class="systemPlan" v-if="sequence.type == 1">{{ $t('robot.systemPlan') }}</span>
                                <span class="customPlan" v-else>{{ $t('robot.customPlan') }}</span>
                                {{getSequenceName(sequence)}}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 序列点位 -->
                <template v-if="!isStartOrStop">
                    <div class="item alignStart" v-for="(data, index) in timePriceList" :key="index">
                        <div class="title">{{ index==0?$t('robot.sequential'):''}}</div>
                        <div class="rangeBox">
                            <div class="subTitle">
                                <span>{{$t('robot.position')+(index+1)}}</span>
                                <span class="g_btn" @click="timePriceList.length>1?timePriceList.splice(index,1):null">
                                    <i class="iconfont2 icon-shanchu"></i>
                                </span>
                            </div>
                            <div class="timePriceBox">
                                <el-date-picker :editable="false" v-model="data.time" class="inp sequenceTime"
                                    type="datetime" :placeholder="$t('robot.timePoint')" value-format="timestamp"
                                    :clearable="false">
                                </el-date-picker>
                                <!-- <dateTime :paramTime.sync="data.time"></dateTime> -->
                                <el-input v-model="data.price" @input.native="inputHandlerPrice($event,index)"
                                    :placeholder="$t('robot.target')" class="inp" />
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title"></div>
                        <div class="addBtn" @click="timePriceList.push({time:'',price:''})">
                            <i class="iconfont2 icon-jia"></i>
                            {{$t('robot.newPosition')}}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="itemBox" v-for="(data, index) in timePriceList" :key="index">
                        <div class="subTitle">
                            <span>{{$t('robot.position')+(index+1)}}</span>
                            <span class="g_btn" @click="timePriceList.length>1?timePriceList.splice(index,1):null">
                                <i class="iconfont2 icon-shanchu"></i>
                            </span>
                        </div>
                        <div class="timePriceBox">
                            <el-date-picker :editable="false" v-model="data.time" class="inp sequenceTime"
                                type="datetime" :placeholder="$t('robot.timePoint')" value-format="timestamp"
                                :clearable="false">
                            </el-date-picker>
                            <!-- <dateTime :paramTime.sync="data.time"></dateTime> -->
                            <el-input v-model="data.price" @input.native="inputHandlerPrice($event,index)"
                                :placeholder="$t('robot.target')" class="inp" />
                        </div>
                    </div>
                    <div class="itemBox g_btn btn" @click="timePriceList.push({time:'',price:''})">
                        <i class="iconfont2 icon-jia"></i>
                        {{$t('robot.newPosition')}}
                    </div>
                </template>

            </template>

            <!-- gas倍数 -->
            <template v-if="solWebList.indexOf(web) !== -1">
                <div class="item">
                    <div class="title">{{$t('robot.gasMultiplier')}}</div>
                    <div class="text">
                        <el-input v-model="paramArr[8]" @input.native="inputHandler($event,8)"
                            :placeholder="$t('common.value')" class="inp" />
                        <span class="unit">{{$t('robot.times')}}</span>
                    </div>
                </div>
                <div class="item2">
                    <div class="text2">* {{$t('robot.gasMultiplierTip')}}</div>
                </div>
            </template>

            <div class="item">
                <div class="title">{{$t('robot.walletGroup')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[9]" :placeholder="$t('common.select')">
                        <el-option v-for="(group,index) in walletGroupList" :label="group.name" :value="group.id" :key="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- 新：Dex刷量机器人 -->
        <div class="form shua_dex2" v-else-if="robotType=='shua_dex2'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.priceRange')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transaction')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.intervalTime')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input :disabled="shuaDex2HighMode==1" v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.operationMode')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[5]" :placeholder="$t('common.select')">
                        <el-option :label="$t('robot.normalMode')" value="0">
                        </el-option>
                        <el-option :label="$t('robot.batchOrderMode')" value="1">
                        </el-option>
                        <el-option :label="$t('robot.unlimitedWalletMode')" value="2">
                        </el-option>
                        <el-option :label="$t('robot.newWalletMode')" value="3">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!-- gas倍数 -->
            <template v-if="solWebList.indexOf(web) !== -1">
                <div class="item">
                    <div class="title">{{$t('robot.gasMultiplier')}}</div>
                    <div class="text">
                        <el-input v-model="paramArr[6]" @input.native="inputHandler($event,6)"
                            :placeholder="$t('common.value')" class="inp" />
                        <span class="unit">{{$t('robot.times')}}</span>
                    </div>
                </div>
                <div class="item2">
                    <div class="text2">* {{$t('robot.gasMultiplierTip')}}</div>
                </div>
            </template>

            <div class="item">
                <div class="title">{{$t('robot.walletGroup')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[7]" :placeholder="$t('common.select')">
                        <el-option v-for="(group,index) in walletGroupList" :label="group.name" :value="group.id" :key="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="checkBox">
                <div class="checkboxItem">
                    <span class="label">{{$t('robot.HighFrequency')}}</span>
                    <el-checkbox true-label="1" false-label="0" v-model="shuaDex2HighMode"></el-checkbox>
                </div>
            </div>
        </div>
        <!-- Dex跟盘机器人 -->
        <div class="form real_genpan_dex" v-else-if="robotType=='real_genpan_dex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.targetDisk')}}</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly
                        class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetPair')}}</div>
                <div class="doubletext">
                    <el-input v-model="paramArr[1]" type="text" :placeholder="$t('common.currency')" class="inp" />
                    <i class="split"></i>
                    <el-input v-model="paramArr[2]" type="text" :placeholder="$t('common.currency')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.scaling')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.intervalTime')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transactionMax')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            
            <div class="item">
                <div class="title">{{$t('robot.walletGroup')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[6]" :placeholder="$t('common.select')">
                        <el-option v-for="(group,index) in walletGroupList" :label="group.name" :value="group.id" :key="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- Dex高抛低吸 -->
        <div class="form buy_sell_dex" v-else-if="robotType=='buy_sell_dex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.higherthan')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="red unit block">{{$t('robot.sellout')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.below')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="green unit block">{{$t('robot.purchase')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[2]" @input.native="inputHandler2($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.per')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maximum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.minimum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <!-- gas倍数 -->
            <template v-if="solWebList.indexOf(web) !== -1">
                <div class="item">
                    <div class="title">{{$t('robot.gasMultiplier')}}</div>
                    <div class="text">
                        <el-input v-model="paramArr[6]" @input.native="inputHandler($event,6)"
                            :placeholder="$t('common.value')" class="inp" />
                        <span class="unit">{{$t('robot.times')}}</span>
                    </div>
                </div>
                <div class="item2">
                    <div class="text2">* {{$t('robot.gasMultiplierTip')}}</div>
                </div>
            </template>

            <div class="item">
                <div class="title">{{$t('robot.walletGroup')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[7]" :placeholder="$t('common.select')">
                        <el-option v-for="(group,index) in walletGroupList" :label="group.name" :value="group.id" :key="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- Dex抢买机器人 -->
        <div class="form frontrun_dex" v-else-if="robotType=='frontrun_dex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.openingTime')}}</div>
                <div class="text">
                    <el-date-picker :editable="false" :clearable="false" class="datetime inp" v-model="paramArr[0]"
                        value-format="timestamp" type="datetime" :placeholder="$t('common.selectdate')"
                        :picker-options="pickerNowAfter" />
                    <!-- <dateTime :paramTime.sync="paramArr[0]"></dateTime> -->
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.slippage')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.gas')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{solWebList.indexOf(web) == -1?'GWEI':$t('robot.times')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transactionAmount')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.walletGroup')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[4]" :placeholder="$t('common.select')">
                        <el-option v-for="(group,index) in walletGroupList" :label="group.name" :value="group.id" :key="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- Dex狙击新盘 下架 -->
        <div class="form snipe_dex" v-else-if="robotType=='snipe_dex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.contract')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" :placeholder="$t('robot.contract')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.orderAmount')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit green">{{yenUnit2_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.multiple')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit green">{{$t('robot.times')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.openingTime')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" :placeholder="$t('common.input')" class="inp" />
                </div>
            </div>
            <div class="item" v-if="!isStartOrStop">
                <div class="title">{{$t('robot.deduction')}}</div>
                <div class="text">
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[4]"></el-checkbox>
                </div>
            </div>
            <div class="checkBox" v-else>
                <div class="checkboxItem">
                    <span class="label">{{$t('robot.deduction')}}</span>
                    <el-checkbox true-label="1" false-label="0" v-model="paramArr[4]"></el-checkbox>
                </div>
            </div>
        </div>
        <!-- 旧版：DEX搬砖机器人 下架 -->
        <div class="form banzhuan_dex" v-else-if="robotType=='banzhuan_dex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.targetExchange')}}(CEX)</div>
                <div class="select" @click="openPopSon('0')">
                    <el-input v-model="targetExchange" type="text" :placeholder="$t('common.select')" readonly
                        class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.currentCharge')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.targetCharge')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">%</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.priceDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityDecimal')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                </div>
            </div>
        </div>
        <!-- 新：dex分批卖出机器人 -->
        <div class="form iceberg_dex" v-else-if="robotType=='iceberg_dex'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.pair')}}</div>
                <div class="doubletext">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                    <i class="split"></i>
                    <el-input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp"
                        :readonly="isDis==1" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.floorPrice')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit2_Up}}</span>

                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval2')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[1]" @input.native="inputHandler2($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.quantityPerTime')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.totalShipments')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.walletGroup')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[4]" :placeholder="$t('common.select')">
                        <el-option v-for="(group,index) in walletGroupList" :label="group.name" :value="group.id" :key="index">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <!-- NFT画线机器人 -->
        <div class="form shua_nft" v-else-if="robotType=='shua_nft'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.opensea')}}</div>
                <div class="select">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.opensea')" readonly class="inp" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.priceRange')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.transaction')}}</div>
                <div class="range">
                    <el-input v-model="paramArr[2]" @input.native="inputHandler2($event,2)"
                        :placeholder="$t('common.minValue')" class="inp" />
                    <span class="g_to">{{$t('common.to')}}</span>
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('common.maxValue')" class="inp" />
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.intervalTime')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.direction')}}</div>
                <div class="text">
                    <el-select class="inp" v-model="paramArr[5]" :placeholder="$t('common.select')">
                        <el-option :label="$t('robot.oscillation')" value="0">
                        </el-option>
                        <el-option :label="$t('robot.rise')" value="1">
                        </el-option>
                        <el-option :label="$t('robot.fall')" value="2">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- NFT高抛低吸 -->
        <div class="form buy_sell_nft" v-else-if="robotType=='buy_sell_nft'">
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.currentOpen')}}</div>
                <div class="select" @click="openExchangePop">
                    <el-input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                    <i class="iconfont icon-down"></i>
                </div>
            </div>
            <div class="item" :class="{dis:isDis==1}" v-if="isAddPage">
                <div class="title">{{$t('common.opensea')}}</div>
                <div class="select">
                    <el-input v-model="yenUnit1" type="text" :placeholder="$t('common.opensea')" readonly class="inp" />
                </div>
            </div>

            <div class="item">
                <div class="title">{{$t('robot.higherthan')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[0]" @input.native="inputHandler($event,0)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="red unit block">{{$t('robot.sellout')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.below')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[1]" @input.native="inputHandler($event,1)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="green unit block">{{$t('robot.purchase')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.interval')}}</div>
                <div class="text">
                    <!-- $t('common.max')+' 600'  :max="600" -->
                    <el-input v-model="paramArr[2]" @input.native="inputHandler2($event,2)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{$t('robot.second')}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.per')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[3]" @input.native="inputHandler2($event,3)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>

                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.maximum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[4]" @input.native="inputHandler2($event,4)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
            <div class="item">
                <div class="title">{{$t('robot.minimum')}}</div>
                <div class="text">
                    <el-input v-model="paramArr[5]" @input.native="inputHandler2($event,5)"
                        :placeholder="$t('common.value')" class="inp" />
                    <span class="unit">{{yenUnit1_Up}}</span>
                </div>
            </div>
        </div>

        <!-- 编辑 -->
        <div class="foot" v-if="!isStartOrStop">
            <div class="btn" @click="cancel">{{$t('common.back')}}</div>
            <div v-if="state==0" class="btn submit" @click="confirm">{{$t('common.saveRun')}}</div>
            <!-- 重启 -->
            <div v-else-if="state==1" class="btn submit" @click="confirm">{{$t('common.save')}}</div>
        </div>
        <!-- 手动操盘-开始/停止/重启 -->
        <div class="foot" v-else>
            <div v-if="state==0" class="btn submit" @click="confirm">{{$t('common.start')}}</div>
            <div v-else-if="state==1" class="btn plain" @click="confirm">{{$t('common.stop')}}</div>
            <!-- 仅保存 -->
            <div class="btn plain2" @click="confirm('onlySave')">{{$t('common.save')}}</div>
            <!-- 重启 -->
            <div v-if="state==1" class="btn submit" @click="confirm('isRestart')">{{$t('common.restart')}}</div>
        </div>
    </div>
</template>
<script>
    import Decimal from 'decimal.js'
    import { Loading } from 'element-ui';
    import { mapGetters } from 'vuex'
    // import dateTime from '@/components/dateTime.vue';//自定义时间组件
    import scientificNotationFormat from '@/utils/methApi/scientificNotationFormat'
    import { getPankou, getGroups } from '@/api/markets'
    import {
        getPriceSequenceList,
        getPriceSequenceDetail,
        savePriceSequence,
        getTicker,
    } from '@/api/robot'

    export default {
        // components: { dateTime },
        props: [
            'robotType',
            'openPop',
            'yenUnit1Old',
            'yenUnit2Old',
            // 手动操盘的数据
            'exchange',
            'isDis',
            'web',
            'isStartOrStop',//是否是启动和停止模式-是否是手动操盘进入的
        ],
        data() {
            return {
                loadingSerive: '',

                state: 0,//机器人对应状态
                paramArr: [],//表单数据
                targetExchange: '',
                // 目标交易对-13跟盘机器人、3跨所对冲砖机器
                targetPair1: '',
                targetPair2: '',

                sub_type_select: '1',

                oldparam: '',//修改前的机器人参数，用于保存时做判断

                average: '',// 盘口买入卖出中间值
                latestTransactionPrice: 0,// 最新成交价

                // 画线机器人/dex画线
                sequentialNewId: 0,// 序列新id，0-新建序列 具体id-覆盖序列
                sequentialNewName: '',// 序列新名称
                priceSequenceList: [],// 序列方案列表
                timePriceList: [{ time: '', price: '' }],// 动态添加时间点和目标价数据组
                timePriceListJson: '[]',
                // 钱包分组列表
                walletGroupList: [],

                // dex刷量-是否开启高频模式
                shuaDex2HighMode: '0',

                yenUnit1: '',
                yenUnit2: '',

                pickerNowAfter: {
                    disabledDate(time) {
                        // 获取今天的日期，不包括时间
                        let today = new Date();
                        today.setHours(0, 0, 0, 0);

                        // 禁止选择今天之前的日期
                        return time.getTime() < today.getTime();
                    }
                }
            }
        },
        computed: {
            ...mapGetters(['account', 'lang', 'solWebList', 'robotListDex']),
            yenUnit1_Up() {
                if (this.yenUnit1) {
                    return this.yenUnit1.toUpperCase()
                } else {
                    return this.yenUnit1Old.toUpperCase()
                }
            },
            yenUnit2_Up() {
                if (this.yenUnit2) {
                    return this.yenUnit2.toUpperCase()
                } else {
                    return this.yenUnit2Old.toUpperCase()
                }
            },
            // 是否在添加机器人页面
            isAddPage() {
                return this.$route.path == '/addrobot'
            },
        },
        methods: {
            // 手动操盘时 机器人列表清除param数据
            paramToNull() {
                this.yenUnit1 = this.yenUnit2 = ''
                this.state = 0
                this.paramArr = []

                this.timePriceList = [{ time: '', price: '' }]
                this.initDefault()
            },
            // 手动操盘时 修改序列方案列表
            setPriceSequenceList(priceSequenceList) {
                this.priceSequenceList = priceSequenceList
            },
            // 手动操盘时 修改钱包分组列表
            setWalletGroupList(walletGroupList) {
                this.walletGroupList = walletGroupList
            },

            // 正数-timePriceList
            inputHandlerPrice(event, position) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.timePriceList[position], 'price', value >= 0 ? value : '');
                });
            },
            // 正数
            inputHandler(event, position, max) {
                let value = event.target.value.replace(/[^0-9.]/g, '');
                value = value > max ? max : value;
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.paramArr, position, value >= 0 ? value : '');
                });
            },
            // 正整数
            inputHandler2(event, position) {
                let value = event.target.value.replace(/^(0+)|[^\d]+/g, '');
                // 避免中文输入法影响
                setTimeout(() => {
                    this.$set(this.paramArr, position, value >= 0 ? Number(value) : '');
                });
            },

            // 价格序列名称-多语言
            getSequenceName(sequence) {
                if (this.lang === 'en') return sequence.name;
                if (this.lang === 'zh') return sequence.name_cn;
                if (this.lang === 'kor') return sequence.name_kor;
                return sequence.name;
            },

            // 初始化param默认值
            initDefault(param) {
                // 默认值
                if (this.robotType == 'wangge' && !param) {
                    // 马丁默认值
                    param = '20 5 0.013 0.003 0.03 0.003 0 2'
                } else if (this.robotType == 'grid' && !param) {
                    // 网格默认值
                    param = '20 30 0.01 0.01 1 1'
                }

                this.paramArr = param ? param.split(' ') : [];

                // 特殊类型判定处理
                if (this.robotType == 'buy_sell') {//高抛低吸
                    // 特殊情况非必填项
                    this.paramArr[3] = this.paramArr[3] != 0 ? this.paramArr[3] : '';
                    // 有选项框的类型 给默认值
                    this.paramArr[4] = this.paramArr[4] ? this.paramArr[4] : '0';
                    this.paramArr[7] = this.paramArr[7] ? this.paramArr[7] : '0';
                    this.paramArr[8] = this.paramArr[8] ? this.paramArr[8] : '0';
                    this.paramArr[9] = this.paramArr[9] ? this.paramArr[9] : '0';
                }
                else if (this.robotType == 'banzhuan') {//搬砖
                    // 跨所对冲-搬砖 默认交易对
                    if (this.paramArr[1]) {
                        let targetPairArr = this.paramArr[1].split('_')
                        this.targetPair1 = targetPairArr[0]
                        this.targetPair2 = targetPairArr[1]
                    } else {
                        var targetPair = this.yenUnit1 + '_' + this.yenUnit2
                        this.paramArr[1] = targetPair
                        this.targetPair1 = this.yenUnit1
                        this.targetPair2 = this.yenUnit2
                    }
                }
                else if (this.robotType == 'wangge') {//马丁
                    // 有选项框的类型 给默认值
                    this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : '0';
                }
                else if (this.robotType == 'grid') {//网格
                    // 后续默认参数（高级选项） 0 0 15m 0.1 0 0 0
                    this.paramArr[6] = '0';
                    this.paramArr[7] = '0';
                    this.paramArr[8] = '15m';
                    this.paramArr[9] = '0.1';
                    this.paramArr[10] = '0';
                    this.paramArr[11] = '0';
                    this.paramArr[12] = '0';
                }
                else if (this.robotType == 'shua') {//成交量对敲
                    this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : '0';
                    this.paramArr[7] = this.paramArr[7] ? this.paramArr[7] : '0';
                    this.paramArr[8] = this.paramArr[8] ? this.paramArr[8] : '0';
                }
                else if (this.robotType == 'hupan_buy' || this.robotType == 'hupan_sell') {
                    // 买、卖盘护盘机器人兼容
                    this.paramArr[0] = this.paramArr[0] ? Number(new Decimal(this.paramArr[0]).mul(new Decimal(100))) : ''
                    this.paramArr[1] = this.paramArr[1] ? Number(new Decimal(this.paramArr[1]).mul(new Decimal(100))) : ''
                }
                else if (this.robotType == 'push') {//趋势推动
                    this.paramArr[1] = this.paramArr[1] ? this.paramArr[1] : '1000'
                }
                else if (this.robotType == 'draw') {//画线
                    this.paramArr[9] = this.paramArr[9] ? this.paramArr[9] : '0';
                    this.paramArr[10] = this.paramArr[10] ? this.paramArr[10] : '0';
                }
                else if (this.robotType == 'real_genpan') {
                    // 跟盘机器人兼容
                    if (this.paramArr.length <= 8) {
                        if (this.paramArr.length == 0) {
                            this.paramArr = [null]
                        }
                        var targetPair = this.yenUnit1 + '_' + this.yenUnit2
                        this.paramArr.splice(1, 0, ...[targetPair, 100])
                        this.targetPair1 = this.yenUnit1
                        this.targetPair2 = this.yenUnit2
                    } else {
                        let targetPairArr = this.paramArr[1].split('_')
                        this.targetPair1 = targetPairArr[0]
                        this.targetPair2 = targetPairArr[1]
                        this.paramArr[2] = this.paramArr[2] ? Number(new Decimal(this.paramArr[2]).mul(new Decimal(100))) : ''//this.paramArr[2]*100
                    }
                }
                else if (this.robotType == 'open_cex') {//开盘
                    this.paramArr[0] = this.paramArr[0] ? Number(new Decimal(this.paramArr[0]).mul(new Decimal(1000))) : ''//时间戳10位的
                }
                else if (this.robotType == 'shua_dex') {//Dex画线机器人
                    // 有选项框的类型 给默认值
                    this.paramArr[5] = this.paramArr[5] ? this.paramArr[5] : '0';
                    this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : '0';
                    this.paramArr[7] = this.paramArr[7] ? this.paramArr[7] : null;
                    // gas倍数 默认值
                    this.paramArr[8] = this.paramArr[8] ? this.paramArr[8] : '1';
                    // 钱包分组
                    this.paramArr[9] = this.paramArr[9] ? this.paramArr[9] : '-999';
                }
                else if (this.robotType == 'shua_dex2') {//dex刷量机器人
                    // 有选项框的类型 给默认值
                    this.paramArr[5] = this.paramArr[5] ? this.paramArr[5] : '0';
                    // gas倍数 默认值
                    this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : '1';
                    // 钱包分组
                    this.paramArr[7] = this.paramArr[7] ? this.paramArr[7] : '-999';
                    // 高频模式-是否开启
                    this.shuaDex2HighMode=this.paramArr[4]=='0' ? '1' : '0';
                } else if (this.robotType == 'real_genpan_dex') {//dex跟盘机器人
                    // 钱包分组 有选项框的类型 给默认值
                    this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : '-999';
                }
                else if (this.robotType == 'buy_sell_dex') {//dex高抛低吸
                    // gas倍数 默认值
                    this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : '1';
                    // 钱包分组
                    this.paramArr[7] = this.paramArr[7] ? this.paramArr[7] : '-999';
                }
                else if (this.robotType == 'frontrun_dex') {//dex抢买
                    // 有选项框的类型 给默认值
                    // this.paramArr[2] = this.paramArr[2] ? this.paramArr[2] : '0';
                    this.paramArr[0] = this.paramArr[0] ? Number(new Decimal(this.paramArr[0]).mul(new Decimal(1000))) : ''//时间戳10位的
                    // 钱包分组
                    this.paramArr[4] = this.paramArr[4] ? this.paramArr[4] : '-999';
                }
                else if (this.robotType == 'iceberg_dex') {//dex分批卖出
                    // 钱包分组 有选项框的类型 给默认值
                    this.paramArr[4] = this.paramArr[4] ? this.paramArr[4] : '-999';
                }
                else if (this.robotType == 'snipe_dex') {//dex狙击-下架
                    // 有选项框的类型 给默认值
                    this.paramArr[4] = this.paramArr[4] ? this.paramArr[4] : '0';
                }

                // 交易所
                if (
                    this.robotType == 'banzhuan' ||
                    this.robotType == 'real_genpan' ||
                    this.robotType == 'real_genpan_quick' ||
                    this.robotType == 'real_genpan_dex' ||
                    this.robotType == 'banzhuan_dex'
                ) {
                    this.targetExchange = this.paramArr[0];
                }
            },
            // 传入param数据格式化
            async paramInit(state, param, subTypeNum) {
                this.state = state

                this.yenUnit1 = this.yenUnit1Old
                this.yenUnit2 = this.yenUnit2Old
                // console.log('oldparam',param)
                this.oldparam = param
                if (subTypeNum == 2) {
                    this.sub_type_select = subTypeNum
                }

                // 初始化默认值
                this.initDefault(param)

                // 如果手动操盘 则不需要在获取可选序列列表、钱包分组
                if (this.isStartOrStop) {
                    // 如果画线/dex画线/dex刷量，且序列id存在，则获取序列详情
                    if (this.robotType == 'draw' && this.paramArr[10]) {
                        await this.getSequenceInfo(this.paramArr[10])
                    } else if (this.robotType == 'shua_dex' && this.paramArr[7]) {
                        await this.getSequenceInfo(this.paramArr[7])
                    }
                } else {
                    this.$nextTick(()=>{
                        this.loadingSerive = Loading.service({
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)',
                            customClass: 'apploading',
                        })
                    })
                    try {
                        await Promise.all([
                            // 获取价格序列列表及详情
                            this.updatePriceSequence(),
                            // 获取钱包分组列表
                            this.getWalletGroupList()
                        ]);
                    } finally {
                        this.loadingSerive.close()
                    }
                }
            },
            // 获取价格序列列表及详情
            async updatePriceSequence() {
                // 如果是画线/dex画线，则需要获取机器人可选序列列表
                if (
                    this.robotType == 'draw' ||
                    this.robotType == 'shua_dex'
                ) {
                    try {
                        let res = await getPriceSequenceList({
                            account: this.account,
                            web: this.web,
                            currency: this.yenUnit1_Up + '_' + this.yenUnit2_Up,
                        })

                        this.priceSequenceList = res || []

                        // 如果画线/dex画线/dex刷量，且序列id存在，则获取序列详情
                        if (this.robotType == 'draw' && this.paramArr[10]) {
                            await this.getSequenceInfo(this.paramArr[10])
                        } else if (this.robotType == 'shua_dex' && this.paramArr[7]) {
                            await this.getSequenceInfo(this.paramArr[7])
                        }
                    } catch (error) {
                        console.log(error)
                        this.priceSequenceList = []
                    }
                }
            },
            // 获取钱包分组列表
            async getWalletGroupList() {
                // 如果是dex且不为跨所对冲，则获取钱包分组列表
                if (this.robotListDex.find(robot => robot.robotType === this.robotType) && this.robotType != 'banzhuan') {
                    try {
                        var res = await getGroups({
                            account: this.account,
                            web: this.web
                        })
                        this.walletGroupList = res || []
                    } catch (error) {
                        console.log(error)
                        this.walletGroupList = []
                    }
                }
            },
            // 关闭弹窗时初始化
            init() {
                this.$nextTick(() => {
                    this.paramArr = [];
                    this.targetExchange = '';
                    this.sub_type_select = '1';

                    // 画线/dex画线序列列表、详情初始化
                    this.priceSequenceList = []
                    this.timePriceList = [{ time: '', price: '' }]
                    this.sequentialNewId = 0;
                    this.sequentialNewName = '';
                })
            },
            cancel() {
                this.$emit('handleCancel');
            },
            async confirm(confirmType) {
                // console.log('son-confirm',this.paramArr,'robotType',this.robotType)

                // 特殊判断
                if (this.robotType == 'budan_new' && Number(this.paramArr[3]) > Number(this.paramArr[2])) {
                    // 盘口管理机器人加限制 档位差必须小于等于盘口差, 否则报错.
                    this.$message({
                        message: this.$t('tip.priceDifferenceErr'),
                        type: 'error',
                        center: true,
                        offset: 100,
                        customClass: 'shotMsg',
                    });
                    return
                }
                else if (this.robotType == 'push' && (Number(this.paramArr[1]) > Number(this.paramArr[0]))) {
                    // 趋势推动机器人 单笔预算小于等于总预算
                    this.$message({
                        message: this.$t('tip.priceErrQuShi'),
                        type: 'error',
                        center: true,
                        offset: 100,
                        customClass: 'shotMsg',
                    });
                    return
                }
                else if (this.robotType == 'draw' && Number(this.paramArr[0]) < Number(this.paramArr[1])) {
                    // 画线机器人-范围左右值比较 区间上限必须大于等于区间下限
                    this.$message({
                        message: this.$t('robot.limitTip'),
                        type: 'error',
                        center: true,
                        offset: 100,
                        customClass: 'shotMsg',
                    });
                    return
                }
                else if (this.robotType == 'defence' && Number(this.paramArr[3]) < Number(this.paramArr[4])) {
                    // 防御机器人-范围左右值比较 币上限必须大于等于币下限
                    this.$message({
                        message: this.$t('robot.maxNumContraryTip'),
                        type: 'error',
                        center: true,
                        offset: 100,
                        customClass: 'shotMsg',
                    });
                    return
                }

                // 非空判定
                var empty = false;
                var inputList = document.querySelectorAll('.add_form .' + this.robotType + ' .el-input__inner')
                try {
                    inputList.forEach((item, index) => {
                        // console.log(item.value)
                        if (!item.value) {
                            // 高抛低吸机器人，且当第4、7为选中项时允许第3(添加页需要+3,即第6)项为空
                            if (
                                this.robotType == 'buy_sell' &&
                                ((index == 3 && !this.isAddPage) || (index == 6 && this.isAddPage)) &&
                                (this.paramArr[4] == 1 || this.paramArr[7] == 1)
                            ) {
                                // this.paramArr[3]=0
                                return
                            }
                            // 如果dex刷量，且第4项不存在或者为0时
                            else if(
                                this.robotType == 'shua_dex2' &&
                                index == 4 &&
                                this.shuaDex2HighMode == 1
                            ){
                                return
                            }
                            throw Error()
                        }
                    })
                }
                catch {
                    empty = true;
                    this.$message({
                        message: this.$t('tip.dataErr'),
                        type: 'error',
                        center: true,
                        offset: 100,
                        customClass: 'shotMsg',
                    });
                }
                // 非空
                if (!empty) {
                    // 需要获取盘口均价用于判断的机器人
                    if (
                        this.robotType == 'shua' ||//成交量对敲机器人
                        this.robotType == 'shua_dex' ||//dex画线
                        this.robotType == 'shua_dex2'//dex刷量
                    ) {
                        await this.getPankou()
                        if (this.average == 0) {
                            // 无法读取盘口数据机器人无法正常工作
                            await this.$confirm(this.$t('robot.pankouReadFail'), this.$t('tip.tips'), {
                                confirmButtonText: this.$t('common.confirm2'),
                                center: true,
                                showClose: false,
                                customClass: 'deleteOk',
                                showCancelButton: false,
                                cancelButtonText: this.$t('common.back'),
                            })
                            return
                        }
                    }

                    // 如果画线/dex画线且为序列模式
                    if ((this.robotType == 'draw' && this.paramArr[9] == 10) || (this.robotType == 'shua_dex' && this.paramArr[6] == 10)) {
                        try {
                            // 判断点位价格是否超出价格范围
                            var priceMin = this.robotType == 'draw' ? Number(this.paramArr[1]) : Number(this.paramArr[0])
                            var priceMax = this.robotType == 'draw' ? Number(this.paramArr[0]) : Number(this.paramArr[1])

                            var nowTime = new Date().getTime()
                            this.timePriceList.forEach(item => {
                                if (item.time < nowTime) {
                                    throw Error('timeErr')
                                }
                                if (item.price > priceMax || item.price < priceMin) {
                                    throw Error()
                                }
                            })
                        } catch (err) {
                            try {
                                if (err.message == 'timeErr') {
                                    this.$message({
                                        message: this.$t('robot.timeExpiredErr'),
                                        type: 'error',
                                        center: true,
                                        offset: 100,
                                        customClass: 'shotMsg',
                                    });
                                    throw new Error()
                                }
                                await this.$confirm(this.$t('robot.priceGapErr'), this.$t('tip.tips'), {
                                    confirmButtonText: this.$t('common.confirm2'),
                                    cancelButtonText: this.$t('common.back'),
                                    center: true,
                                    showClose: false,
                                    customClass: 'deleteOk',
                                    dangerouslyUseHTMLString: true
                                }).then(res => { })
                            } catch (err) {
                                return
                            }
                        }

                        // 如果序列被修改过，则弹窗保存/更新序列
                        if (
                            this.timePriceListJson != JSON.stringify(this.timePriceList)
                        ) {
                            try {
                                await this.$confirm(this.sequentialNewId == 0 ? this.$t('robot.sequenceNaming') : this.$t('robot.sequenceNamingHasOverride'), this.$t('tip.tips'), {
                                    confirmButtonText: this.$t('common.save'),
                                    cancelButtonText: this.$t('common.cancel'),
                                    showCancelButton: this.sequentialNewId != 0,
                                    inputType: "text",
                                    inputPlaceholder: this.$t('robot.sequentialNameEmpty'),
                                    showInput: true,
                                    inputValue: this.sequentialNewName, // 这是绑定输入框的变量
                                    beforeClose: (action, instance, done) => {
                                        let value = instance.inputValue
                                        this.loadingSerive = Loading.service({
                                            text: 'Loading',
                                            spinner: 'el-icon-loading',
                                            background: 'rgba(0, 0, 0, 0.7)',
                                            customClass: 'apploading',
                                        })
                                        if (action == 'confirm') {//保存
                                            if (!value) {
                                                this.$message.error(this.$t('robot.sequentialNameEmpty'));
                                                this.loadingSerive.close()
                                                return
                                            }
                                        }
                                        else {//关闭
                                            this.loadingSerive.close()
                                            done()
                                            return
                                        }

                                        // 发送请求，使用 value 作为参数
                                        let timePriceList = []
                                        this.timePriceList.forEach(item => {
                                            let obj = JSON.parse(JSON.stringify(item))
                                            obj.time = obj.time / 1000
                                            timePriceList.push(obj)
                                        })
                                        let process = JSON.stringify(timePriceList)

                                        savePriceSequence({
                                            process,
                                            name: value,
                                            account: this.account,
                                            web: this.web,
                                            currency: this.yenUnit1_Up + '_' + this.yenUnit2_Up,
                                            id: value == this.sequentialNewName ? this.sequentialNewId : 0//名称改变则创建新方案
                                        }).then(res => {
                                            // console.log(res)
                                            // 存储序列id
                                            if (this.robotType == 'draw') {
                                                this.$set(this.paramArr, '10', '' + res)
                                            } else if (this.robotType == 'shua_dex') {
                                                this.$set(this.paramArr, '7', '' + res)
                                            }

                                            done()
                                            this.loadingSerive.close()

                                            // 更新价格序列列表及详情
                                            this.updatePriceSequence()
                                        }).catch(err => {
                                            this.loadingSerive.close()
                                        })
                                    }
                                })
                            } catch (error) {
                                console.log('error', error)
                                return
                            };
                        }
                    }

                    // 需要对原数组进行修改的部分
                    let paramArr = this.paramArr.concat()
                    if (this.robotType == 'banzhuan') {
                        // 新dex跨所对冲-搬砖 交易对
                        paramArr[1] = this.targetPair1 + '_' + this.targetPair2
                    }
                    else if (this.robotType == 'hupan_buy' || this.robotType == 'hupan_sell') {
                        // 买卖盘护盘机器人
                        paramArr[0] = Number(new Decimal(paramArr[0]).div(new Decimal(100)))
                        paramArr[1] = Number(new Decimal(paramArr[1]).div(new Decimal(100)))
                    }
                    else if (this.robotType == 'draw') {
                        // dex画线 序列id
                        paramArr[10] = !!paramArr[10] ? paramArr[10] : 0
                    }
                    else if (this.robotType == 'real_genpan') {
                        // 跟盘机器人
                        paramArr[1] = this.targetPair1 + '_' + this.targetPair2
                        paramArr[2] = Number(new Decimal(paramArr[2]).div(new Decimal(100)))//this.paramArr[2]/100
                    }
                    else if (this.robotType == 'kongpan') {
                        // 高级盘口管理 消耗量预算隐藏
                        paramArr[0] = 0
                        paramArr[1] = 0
                    }
                    
                    else if (this.robotType == 'open_cex') {//开盘
                        paramArr[0] = Number(new Decimal(paramArr[0]).div(new Decimal(1000)))//时间戳10位的
                    }
                    else if (this.robotType == 'shua_dex') {
                        // dex画线 序列id
                        paramArr[7] = !!paramArr[7] ? paramArr[7] : 0
                    }
                    else if (this.robotType == 'shua_dex2') {
                        // dex刷量 高频
                        paramArr[4] = this.shuaDex2HighMode == 1 ? 0 : paramArr[4]
                    }
                    else if (this.robotType == 'frontrun_dex') {
                        // dex抢买
                        paramArr[0] = Number(new Decimal(paramArr[0]).div(new Decimal(1000)))
                    }

                    var data = paramArr.join(' ')

                    // 匹配连续的空格换成0
                    data = data.replace(/(\s)\1+/g, '$10 ');
                    // console.log(data)
                    
                    // sub_type_select 切换按钮的sub_type
                    var sub_type_select;
                    if (this.robotType == 'push' || this.robotType == 'real_genpan') {//this.robotType=='grid'||
                        // 切换按钮的sub_type
                        sub_type_select = this.sub_type_select
                    }

                    var groupId
                    var groupIdIndex = this.getGroupId(this.robotType)
                    if(groupIdIndex == 'notGroupId'){
                        groupId = '-999'
                    }else{
                        groupId = paramArr[groupIdIndex] || '-999'
                    }

                    // 编辑
                    if (!this.isStartOrStop) {
                        // 判断交易对是否发生变化
                        var currencyNotChange = this.yenUnit1Old.toUpperCase() == this.yenUnit1_Up && this.yenUnit2Old.toUpperCase() == this.yenUnit2_Up
                        // 如果是已启动机器人则判断是否修改了参数
                        if (this.state == 1 && this.oldparam == data && currencyNotChange) {
                            // 检测到参数与已启动的机器人一致，请修改后再保存
                            this.$message({
                                message: this.$t('tip.paramSame'),
                                type: 'error',
                                center: true,
                                offset: 100,
                                customClass: 'shotMsg',
                            });
                        }
                        else {
                            // 只有保存并启动、重启
                            // 判断是已启动的机器人且修改了参数，则需要重启(如果修改了交易对则默认不需要重启)
                            let isRestart;
                            if (this.state == 1 && currencyNotChange) {
                                isRestart = 1
                            } else {
                                isRestart = 0
                            }
                            if (!currencyNotChange) {
                                this.$emit('update:yenUnit1Old', this.yenUnit1)
                                this.$emit('update:yenUnit2Old', this.yenUnit2)
                            }
                            this.startTip(paramArr, data, sub_type_select, isRestart, groupId)
                        }
                    }
                    else {//手动操盘-机器人
                        if (confirmType == 'isRestart') {// 重启
                            this.startTip(paramArr, data, sub_type_select, 'isRestart', groupId)
                        } else if (confirmType == 'onlySave') {//仅保存
                            // this.$emit('handleOnlySave', data, sub_type_select, this.state, groupId)
                            this.startTip(paramArr, data, sub_type_select, 'handleOnlySave', groupId)
                        } else if (this.state == 0) {// 启动 二次确认
                            this.startTip(paramArr, data, sub_type_select, 0, groupId)
                        } else {//停止
                            this.$emit('handleStop', data, sub_type_select, groupId)
                        }
                    }
                }
            },
            // 启动二次确认
            async startTip(paramArr, data, sub_type_select, confirmType, groupId) {
                var tipDetail = ''
                // 内容是否左对齐
                var moreCustomClass=''
                // 是否需要二次启动确认提示
                var needStartAgain = true

                // 特有的二次确认弹窗-隐藏参数提示
                // 成交量对敲机器人
                if (this.robotType == 'shua') {
                    // 最新成交价
                    await this.getTicker()
                    // 预估24h成交量=平均对敲单量*平均每分钟次数*60*24
                    var value24=new Decimal(paramArr[0]).add(new Decimal(paramArr[1])).div(2)
                    .mul(new Decimal(paramArr[2]).add(new Decimal(paramArr[3])).div(2))
                    .mul(60).mul(24)
                    var estimatedFunds = value24.mul(new Decimal(this.latestTransactionPrice))

                    needStartAgain=false
                    moreCustomClass=' confirmContentLeft'
                    tipDetail+=`<div>
                        ${this.$t('robot.estimatedVol24')}: 
                        <span style="color:#E65B1B;">${value24.toString()}</span>
                        ${this.yenUnit1_Up}
                    </div>
                    <div>
                        ${this.$t('robot.estimatedAmount')}: 
                        <span style="color:#E65B1B;">${estimatedFunds.toString()}</span>
                        ${this.yenUnit2_Up}
                        ${this.latestTransactionPrice==0?'<div style="font-size:12px;">('+this.$t('robot.latestTransactionPriceErr')+')</div>':''}
                    </div>
                    <br/>`
                    if(Number(paramArr[3])>60){
                        tipDetail+=`<div style="margin-bottom:12px;">
                            <span style="color:#E65B1B;">${this.$t('robot.securityCloseLabel')}：</span>
                            ${this.$t('robot.securityCloseTip')}
                        </div>
                        <br/>`
                    }
                }
                // 盘口管理机器人
                else if (this.robotType == 'budan_new') {
                    // 最新成交价
                    await this.getTicker()
                    var buyNumRang1 = new Decimal(paramArr[0]).mul(new Decimal(0.7)).toString()
                    var buyNumRang2 = new Decimal(paramArr[0]).mul(new Decimal(1.3)).toString()
                    var sellNumRang1 = new Decimal(paramArr[1]).mul(new Decimal(0.7)).toString()
                    var sellNumRang2 = new Decimal(paramArr[1]).mul(new Decimal(1.3)).toString()
                    var estimatedFundsBuy = new Decimal(paramArr[0]).mul(new Decimal(this.latestTransactionPrice)).mul(new Decimal(paramArr[5])).toString()
                    var estimatedFundsSell = new Decimal(paramArr[1]).mul(new Decimal(this.latestTransactionPrice)).mul(new Decimal(paramArr[5])).toString()

                    needStartAgain=false
                    moreCustomClass=' confirmContentLeft'
                    tipDetail+=`
                        <div>${this.$t('robot.maintenance')}: ${this.$t('robot.maintenanceVal',{num:paramArr[5]})}</div>
                        <div>${this.$t('robot.buyNumRang')}: ${buyNumRang1} ~ ${buyNumRang2} ${this.yenUnit1_Up}</div>
                        <div>${this.$t('robot.sellNumRang')}: ${sellNumRang1} ~ ${sellNumRang2} ${this.yenUnit1_Up}</div>
                        <div style="display:flex;">
                            <div style="margin-right:4px;">${this.$t('robot.estimatedFunds')}:</div>
                            <div>
                                <div>${this.$t('robot.buyOrder')}: ${estimatedFundsBuy} ${this.yenUnit2_Up}</div>
                                <div>${this.$t('robot.sellOrder')}: ${estimatedFundsSell} ${this.yenUnit2_Up}</div>
                            </div>
                        </div>
                        <br/>
                        <div>${this.$t('tip.tip')}</div>
                        <div>${this.$t('robot.pankouManageTip')}</div>
                    <br/>`
                }
                // 趋势推动
                else if (this.robotType == 'push') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.pushBotTip')+'</div><br/>'
                }
                // 高抛低吸机器人 dex高抛低吸机器人
                else if (this.robotType == 'buy_sell'||this.robotType == 'buy_sell_dex') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.buySellBotTip')+'</div><br/>'
                }
                // 跨所对冲机器人
                else if (this.robotType == 'banzhuan') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.banzhuanBotTip',{robot: this.$t('robot.banzhuan.name')})+'</div><br/>'
                }
                // 买盘护盘、卖盘护盘
                else if (this.robotType == 'hupan_buy' || this.robotType == 'hupan_sell') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.hupanBotTip')+'</div><br/>'
                }
                // 画线机器人
                else if (this.robotType == 'draw') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.drawBotTip')+'</div><br/>'
                }
                // 跟盘机器人
                else if (this.robotType == 'real_genpan') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.realGenpanBotTip',{robot:this.$t('robot.real_genpan.name')})+'</div><br/>'
                }
                // 高级盘口管理
                else if (this.robotType == 'kongpan') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.pankouManageTip')+'</div><br/>'
                }
                // DEX画线机器人 DEX刷量机器人
                else if (this.robotType == 'shua_dex'||this.robotType == 'shua_dex2') {
                    needStartAgain=false
                    moreCustomClass=' confirmContentWide'
                    tipDetail+=`
                        <div>${this.$t('robot.shuaDexBotTip')}</div>
                        <div style="font-size:11px">*${this.$t('robot.shuaDexBotTip2')}</div>
                    <br/><br/>`
                }
                // DEX定时买入
                else if (this.robotType == 'frontrun_dex') {
                    needStartAgain=false
                    tipDetail+='<div>' + this.$t('robot.frontrunDexBotTip')+'</div><br/>'
                }

                // 有额外确认弹窗
                // 16dex画线、26dex刷量
                if (this.robotType == 'shua_dex' || this.robotType == 'shua_dex2') {
                    let num0 = Number(paramArr[0])
                    let num1 = Number(paramArr[1])
                    let average = Number(this.average)
                    // 去除科学计数法
                    let price = this.scientificNotationFormat(this.average)

                    // 如果盘口价格存在且价格范围不包含average 则询问是否继续
                    if (
                        (paramArr[0] != 0 && paramArr[1] != 0 && average != 0) &&
                        ((num0 > average && num1 > average) ||
                        (num0 < average && num1 < average))
                    ) {
                        needStartAgain=true
                        tipDetail+=`<div style="text-align:left;margin-bottom:12px;">
                            <span style="color:#E65B1B;">${this.$t('robot.priceRangeWarnLabel')}：</span>
                            ${this.$t('robot.priceRangeWarn', { price })}
                        </div>`
                    }

                    // 计价币不是USDT时比较价格和盘口价格的50倍进行比较
                    if (
                        this.yenUnit2_Up != 'USDT'&&
                        num1 > Number(new Decimal(this.average).mul(50))
                    ) {
                        needStartAgain=true
                        tipDetail+=`<div style="text-align:left;margin-bottom:12px;">
                            <span style="color:#E65B1B;">${this.$t('robot.priceUnitWarnLabel')}：</span>
                            ${this.$t('robot.priceUnitWarn')}
                        </div>`
                    }
                }
                // 25dex分批卖出
                if (this.robotType == 'iceberg_dex' && this.yenUnit2_Up != 'USDT') {
                    await this.getPankou()
                    // 计价币不是USDT时比较价格和盘口价格的50倍进行比较
                    if(Number(paramArr[0]) > Number(new Decimal(this.average).mul(50))){
                        needStartAgain=true
                        tipDetail+=`<div>${this.$t('robot.priceUnitWarn')}</div></br>`
                    }
                }
                
                // 确定
                if(confirmType=='handleOnlySave'){
                    // 二次确认是否仅保存
                    try {
                        await this.$confirm(tipDetail + this.$t('tip.saveRobot'), this.$t('tip.tips'), {
                            confirmButtonText: this.$t('common.confirm2'),
                            cancelButtonText: this.$t('common.back'),
                            center: true,
                            showClose: false,
                            customClass: 'deleteOk' + moreCustomClass,
                            dangerouslyUseHTMLString: true
                        })
                    } catch (error) {
                        return
                    }
                    this.$emit('handleOnlySave', data, sub_type_select, this.state, groupId)
                }else{
                    // 二次确认是否启动
                    try {
                        await this.$confirm(tipDetail + (needStartAgain?this.$t('tip.startRobot'):''), this.$t('tip.tips'), {
                            confirmButtonText: this.$t('common.confirm2'),
                            cancelButtonText: this.$t('common.back'),
                            center: true,
                            showClose: false,
                            customClass: 'deleteOk' + moreCustomClass,
                            dangerouslyUseHTMLString: true
                        })
                    } catch (error) {
                        return
                    }
                    this.$emit('handleConfirm', data, sub_type_select, confirmType, groupId)
                }
            },
            // 获取dex机器人钱包分组对应param中的键位置
            getGroupId(robotType){
                if(robotType=='shua_dex'){
                    return 9
                }
                else if(
                    robotType=='shua_dex2'||
                    robotType=='buy_sell_dex'
                ){
                    return 7
                }
                else if(robotType=='real_genpan_dex'){
                    return 6
                }
                else if(
                    robotType=='frontrun_dex'||
                    robotType=='iceberg_dex'
                ){
                    return 4
                }else{
                    return 'notGroupId'
                }
            },
            // 科学计数法格式化
            scientificNotationFormat(num) {
                return scientificNotationFormat(num)
            },

            // 获取盘口数据
            async getPankou() {
                this.average = 0//初始化

                this.loadingSerive = Loading.service({
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    customClass: 'apploading',
                })
                try {
                    let res = await getPankou({
                        web: this.web,
                        account: this.account,
                        currency: this.yenUnit1_Up + '_' + this.yenUnit2_Up,
                    })
                    this.loadingSerive.close()
                    if (!res) {
                        this.average = 0
                    } else {
                        var sellPrice = !res.bids || !res.bids.length ? 0 : res.bids[0].price;
                        var buyPrice = !res.asks || !res.asks.length ? 0 : res.asks[0].price;
                        this.average = (new Decimal(sellPrice).add(new Decimal(buyPrice))).div(new Decimal(2)).toString()
                    }
                } catch (error) {
                    this.average = 0
                    this.loadingSerive.close()
                }
            },
            // 获取最新成交价
            async getTicker(){
                this.latestTransactionPrice = 0//初始化

                this.loadingSerive = Loading.service({
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    customClass: 'apploading',
                })
                try {
                    let res = await getTicker({
                        web: this.web,
                        account: this.account,
                        currency: this.yenUnit1_Up + '_' + this.yenUnit2_Up,
                    })
                    this.loadingSerive.close()
                    if (!res) {
                        this.latestTransactionPrice = 0
                    } else {
                        this.latestTransactionPrice = res.last || 0
                    }
                } catch (error) {
                    this.latestTransactionPrice = 0
                    this.loadingSerive.close()
                }
            },
            // 画线/dex画线获取序列详情 - 下拉切换
            async getSequenceInfo(sequentialPlanId, position) {
                // 创建新方案
                if (!sequentialPlanId||sequentialPlanId==0) {
                    this.sequentialNewId = 0
                    this.sequentialNewName = ''
                    return
                }
                // 判断方案类型，存储id方案 如果平台方案则0，否则存储方案id
                let targetItem = this.priceSequenceList.find(item => item.id === sequentialPlanId);
                if (targetItem.type == 1) {
                    this.sequentialNewId = 0
                    this.sequentialNewName = this.lang == 'en' ? targetItem.name :
                        this.lang == 'zh' ? targetItem.name_cn :
                            this.lang == 'kor' ? targetItem.name_kor : targetItem.name
                } else {
                    this.sequentialNewId = sequentialPlanId
                    this.sequentialNewName = targetItem.name
                }

                this.$nextTick(() => {
                    this.loadingSerive = Loading.service({
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)',
                        customClass: 'apploading',
                    })
                })
                // 获取序列方案详情
                try {
                    let res = await getPriceSequenceDetail({
                        account: this.account,
                        id: sequentialPlanId,
                        web: this.web,
                        currency: this.yenUnit1_Up + '_' + this.yenUnit2_Up,
                    })
                    let timePriceList = []
                    let process = []
                    if (typeof res.process == 'string') {
                        process = JSON.parse(res.process)
                    } else {
                        process = res.process
                    }
                    process.forEach(item => {
                        item.time = item.time * 1000
                        timePriceList.push(item)
                    })
                    this.timePriceListJson = JSON.stringify(timePriceList)
                    this.timePriceList = timePriceList
                } catch (error) {
                    console.log(error)
                }

                this.$nextTick(() => {
                    this.loadingSerive.close()
                })
            },
            // 交易所
            openExchangePop() {
                if (this.isDis == 1) return
                // this.popweb = this.web;
                // this.isSonPop = false;
                // this.popExchange = true;
                this.$emit('openPop', {
                    web: this.web,
                    num: 'web'
                })
            },
            // 子组件交易所
            openPopSon(index) {
                this.$emit('openPop', {
                    web: this.paramArr[index],
                    num: index
                })
            },
            handleSelect(data, num) {
                // 子组件交易所
                this.targetExchange = data.text;
                this.paramArr[num] = data.web;
            },
        },
    }
</script>

<style scoped="scoped" lang="stylus">
    @import '~@/views/robot/robot.styl';
    @import '~@/views/robot/add.styl';
</style>
<style lang="stylus" scoped>
    .add_form {
        color: var(--white);

        .red {
            color: var(--red);
        }

        .green {
            color: var(--green);
        }

        .form {
            width: 390px;
            padding: 10px;
            box-sizing: border-box;
            margin: 0 auto;

            @media screen and (max-width: 450px) {
                width: 100%;
            }

            .item,
            .itemSwitch {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
            }

            .itemSwitch .switch {
                margin: auto;
            }

            .item2{
                margin-top: -9px;
                margin-bottom: 9px;
                .text2{
                    color: var(--active);
                    font-size: 12px;
                    margin-left: 108px;
                }
            }
            .item {
                &:last-child {
                    margin-bottom: 0;
                }

                .title {
                    width: 100px;
                    margin-right: 8px;
                    word-wrap: break-word;
                    word-break: keep-all;
                    line-height: 1.3;

                    @media screen and (max-width: 450px) {
                        width: 90px;
                    }
                }

                .text {
                    display: flex;
                    align-items: center;
                    flex: none;
                    width: calc(100% - 100px - 8px);

                    @media screen and (max-width: 450px) {
                        width: calc(100% - 90px - 8px);
                    }

                    .inp {
                        width: 217px;
                    }
                }

                //高抛低吸inp + checkbox
                .wrap {
                    flex-wrap: wrap;

                    >div {
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }
                }

                .select,
                .doubletext {
                    .inp {
                        margin: 0;
                        font-size: 12px;
                        height: 32px;
                    }
                }

                .select {
                    position: relative;
                    max-width: calc(100% - 100px - 8px);

                    @media screen and (max-width: 450px) {
                        max-width: calc(100% - 90px - 8px);
                    }

                    /deep/ .el-input__inner {
                        font-size: 12px;
                    }

                    .inp {
                        width: 217px;
                        max-width: 100%;
                        border: none;
                        padding: 0;
                    }

                    .iconfont {
                        position: absolute;
                        right: 5%;
                        top: 0;
                        color: var(--placeholder);
                        font-size: 10px;
                        line-height: 32px;
                    }
                }

                .doubletext {
                    width: calc(100% - 100px - 8px);

                    @media screen and (max-width: 450px) {
                        width: calc(100% - 90px - 8px);
                    }

                    .inp {
                        width: 100px;
                        max-width: calc(50% - 8.5px);
                    }

                    .split {
                        height: 23px;
                        border-right: 1px solid var(--border2);
                        margin: 0 8px;
                    }
                }

                // 区间选择
                .range {
                    width: calc(100% - 100px - 8px);
                    display: flex;
                    align-items: center;

                    .inp {
                        width: 100px;
                        max-width: calc((100% - 25px) / 2);
                        height: 32px;
                    }

                    .line {
                        display: inline-block;
                        width: 17px;
                        text-align: center;
                        color: var(--border2);
                    }
                }

                // 序列时间价格点
                .rangeBox {
                    width: calc(100% - 100px - 8px);
                    border: 1px solid var(--border2);
                    border-radius: 2px;
                    padding: 10px;
                    box-sizing: border-box;

                    .subTitle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 12px;
                    }

                    .timePriceBox {
                        width: 100%;

                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .inp {
                            width: 100%;
                        }

                        .inp+.inp {
                            margin-top: 10px;
                        }

                        /deep/ .sequenceTime {
                            .el-input__inner {
                                padding: 0 10px;
                            }

                            .el-input__prefix {
                                display: none;
                            }
                        }

                        .dateTime {
                            margin-bottom: 10px;
                        }
                    }
                }

                // 单位
                .unit {
                    white-space: nowrap;
                    padding-left: 8px;
                }
            }

            .alignStart {
                align-items: flex-start;

                .title {
                    margin-top: 10px;
                }
            }
        }

        // 禁用
        .dis {
            opacity: 0.4;

            /deep/ .inp {
                cursor: not-allowed;

                input {
                    cursor: not-allowed;
                }
            }
        }

        .addBtn {
            cursor: pointer;
            font-size: 14px;
            width: calc(100% - 100px - 8px);
            line-height: 32px;
            border: 1px solid var(--border2);
            border-radius: 2px;
            padding: 0 10px;
            box-sizing: border-box;

            i {
                font-size: 13px;
            }
        }

        .foot {
            margin: 30px 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                color var(--backBtnFont);
                min-width: 60px;
                height: 32px;
                padding: 0 16px;
                border: 1px solid var(--border2);
                box-sizing: border-box;
                border-radius: 2px;
                cursor: pointer;
                margin: 0 0.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;

                &.submit {
                    background: var(--active);
                    border-color: var(--active);
                    color: #ffffff;
                }

                &.plain {
                    border-color: var(--active);
                    color: var(--active);
                }

                &.plain2 {
                    border-color: var(--plainDefault);
                    color: var(--plainDefault);
                }
            }
        }

        // 单选框
        .el-radio {
            color: var(--white);

            /deep/ .el-radio__input {
                &.is-checked {
                    .el-radio__inner {
                        background-color: var(--active);
                        border-color: var(--active);

                        &::after {
                            transform(translate(0%, 0%) rotate(45deg) scaleY(1));
                        }
                    }
                }

                &.is-checked+.el-radio__label {
                    color: var(--active);
                }

                .el-radio__inner {
                    background-color: var(--radioBg);
                    border-color: var(--radioBg);
                    border-radius: 2px;

                    &::after {
                        width: 3px;
                        height: 7px;
                        background: none;
                        border-radius: 0;
                        border: 1px solid var(--white);
                        border-left: 0;
                        border-top: 0;
                        boxSize(content-box);
                        position: absolute;
                        left: 4px;
                        top: 1px;
                        transform(translate(0%, 0%) rotate(45deg) scaleY(0));
                        transform-origin: center;
                        trans(transform .15s ease-in .05s);
                    }
                }
            }
        }
    }

    .isStocks {
        .form {
            width: 100%;

            .item2{
                .text2{
                    margin-left: 0;
                }
            }
            .item {
                background: var(--formRowBg);
                border-radius: 8px;
                justify-content: space-between;
                padding: 0 2px 0 12px;

                .title {
                    width: auto;
                }

                .text,
                .select,
                .doubletext,
                .range {
                    width: auto;

                    /deep/ .dateTime input {
                        border: none;
                        text-align: right;
                    }

                    /deep/ .el-input__inner {
                        border: none;
                        text-align: right;
                    }
                }

                .text {
                    flex: 1;

                    .inp {
                        width: auto;
                        flex: 1;
                    }
                }

                .select {
                    max-width: 100%;
                    flex: 1;

                    /deep/ .el-input__inner {
                        padding-right: 30px;
                    }

                    .inp {
                        width: 100%;
                    }

                    .iconfont {
                        right: 10px;
                    }
                }

                .range {
                    justify-content: flex-end;

                    .inp {
                        width: auto;
                    }

                    /deep/ .el-input__inner {
                        text-align: center;
                    }
                }

                // 时间选择器
                /deep/ .datetime.el-input--suffix .el-input__inner {
                    padding-right: 10px;
                }


                .unit {
                    padding-right: 8px;
                    padding-left: 0;

                    &.block {
                        min-width: 32px;
                        height: 32px;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        padding: 0px 3px;

                        &.red {
                            background: #FD626A;
                            color: #ffffff;
                        }

                        &.green {
                            background: #21E8E5;
                            color: #242833;
                        }
                    }
                }
            }

            .itemBox {
                margin-bottom: 15px;
                background: var(--formRowBg);
                border-radius: 8px;
                padding: 12px;

                .subTitle {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 12px;
                }

                .timePriceBox {
                    .el-date-editor.el-input {
                        margin-bottom: 6px;
                    }

                    .el-date-editor.el-input {
                        width: 100%;
                    }

                    /deep/ .el-input__inner {
                        border: none;
                    }

                    /deep/ .dateTime {
                        margin-bottom: 6px;

                        input {
                            border: none;
                            background-color: var(--formRowBg2);
                            border-radius: 3px;
                        }
                    }

                    .inp {
                        background-color: var(--formRowBg2);
                        border-radius: 3px;
                    }
                }

                &.btn {
                    justify-content: flex-start;

                    i {
                        margin-right: 5px;
                        font-size: 13px;
                    }
                }
            }

            .checkBox {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .checkboxItem {
                    background: var(--formRowBg);
                    border-radius: 8px;
                    padding: 0 12px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    margin-right: 11px;
                    margin-bottom: 15px;

                    :last-child {
                        margin-right: 0;
                    }

                    .label {
                        margin-right: 14px;
                    }

                    .tipLabel {
                        color: var(--checkboxLabek);
                        font-size: 11px;
                    }
                }
            }
        }
    }

    /deep/ .systemPlan,
    /deep/ .customPlan {
        border-radius: 3px;
        padding: 2px 8px;
        color: #ffffff;
        margin-right: 8px;
    }

    /deep/ .systemPlan {
        background: linear-gradient(180deg, #FF8A54 0%, #E65B1B 100%);
    }

    /deep/ .customPlan {
        background: linear-gradient(180deg, #52596B 0%, #54596A 100%);
    }
</style>